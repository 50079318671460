export const projects = [
    {
        id: 1,
        title: "Lines : a social network",
        img: "assets/icons/working.png",
        link: " ",
        vid: "",
        finished: false,
        filter: "Web",
        featured: false,
        git: "",
        description: "Lines is a social network that I’m developing, this social network is different from the known social networks (Facebook, Instagram, TikTok, …), it only has 2 pages, in the first page there is a bunch of horizontal lines, there is 3 kind of lines, blue for a message, green for a follow, and magenta for mentions. In the second page you can scroll your following publications. This project is not finished yet, I finished the backend and I’m still working on the front end.",
        doneWith: ["nodejs","react","express","mongodb","sass","javascript"],
        images: ["assets/icons/working.png"],
    },
    {
        id: 2,
        title: "Farming game",
        img: "assets/projects/java_project.png",
        link: " ",
        vid: "https://youtu.be/z_OUOGQqDxM",
        finished: true,
        filter: "Software",
        featured: true,
        git: "https://github.com/zaka59/projet-java-2020-21",
        description: "One of the first java games that I created, this project takes part of a big project where you can use the abstract classes to create similar games, a game with a board, where you can deploy characters on the deployable tiles. This particular game it’s about a farming game where you can deploy farmers on the land to make them farm resources for you, the harvest can be exchanged for gold, each round you have to pay the deployed farmers, the winner is the player with the most gold. A GUI version was made for this version. ",
        doneWith: ["java","junit4",],
        images: ["assets/projects/farmgame/farmgame_1.png"],
    },
    {
        id: 3,
        title: "War game",
        img: "assets/projects/java_project.png",
        link: " ",
        vid: "https://youtu.be/4iEQkK8Kybo",
        finished: true,
        filter: "Software",
        featured: false,
        git: "https://github.com/zaka59/projet-java-2020-21",
        description: "One of the first java games that I created, this project takes part of a big project where you can use the abstract classes to create similar games, a game with a board, where you can deploy characters on the deployable tiles. This particular game it’s about a war game where you can deploy your armies on the land to make conquer them, they get resources at the last of each round which you can exchange them for gold, on each round you have to pay the deployed armies, the winner is the player with the most deployed armies. ",
        doneWith: ["java","junit4"],
        images: ["assets/projects/wargame/wargame_1.png","assets/projects/wargame/wargame_2.png","assets/projects/wargame/wargame_3.png","assets/projects/wargame/wargame_4.png"],
    },
    {
        id: 4,
        title: "Sports Competition game",
        img: "assets/projects/java_project.png",
        link: " ",
        vid: "https://youtu.be/01mps8eX5DM",
        finished: true,
        filter: "Software",
        featured: false,
        git: "https://github.com/zaka59/projet-java-2021-22",
        description: "This project concerns the practice of object-oriented design by using various concepts such as (abstraction, design patterns). It also concerns the practice of clean code and software testing and team work. It’s a project where any developer can add a competition just using the given classes without modifying them.3 default competitions where created, a League, a Tournament and a Master competition, using these 3 classes, any competition can be created (Champions League, NFL, NBA…).", 
        doneWith: ["java","junit5"],
        images: ["assets/projects/sports/sports_1.png","assets/projects/sports/sports_2.png","assets/projects/sports/sports_3.png"],
    },
    {
        id: 5,
        title: "Starship game",
        img: "assets/projects/starship/starship_7.png",
        link: "https://starship-zac.netlify.app/",
        vid: "https://youtu.be/nRkHK9ma5VI",
        finished: true,
        filter: "Web",
        featured: true,
        git: "https://github.com/zaka59/starship",
        description:"On my sophomore year at college, we had to create a game with Nodejs, this is my first game in Nodejs. It’s about a starship that has to prevent the enemies from crossing the left side, when you hit an enemy, you gain points and when an enemy cross you lose them points, the enemy’s speeds and frequency grows when you gain points. This project was done with one of my classmates on GitLab.",
        doneWith: ["nodejs","javascript","html","css"],
        images: ["assets/projects/starship/starship_2.png","assets/projects/starship/starship_3.png","assets/projects/starship/starship_4.png"],

    },
    {
        id: 6,
        title: "TO-DO list app",
        img: "assets/projects/todoapp/todoapp_4.png",
        link: "https://todo-app-zac.netlify.app",
        vid:"https://youtu.be/jAgeriAoO1s",
        finished: true,
        filter: "Web",
        featured: false,
        git: "https://github.com/zaka59/todo-list-app",
        description:"A to-do list app made with react, each task has a given priority and they are sorted from the highest to the lowest, you can also set the time that you will take to finish the task, and when the task is finished, it will move to the finished tasks section, this app uses cookies to save your session, the sessions can be cleared with the button “reset session”. ",
        doneWith: ["nodejs","react","javascript","css","html"],
        images: ["assets/projects/todoapp/todoapp_1.png","assets/projects/todoapp/todoapp_2.png", "assets/projects/todoapp/todoapp_3.png"],
    },
    {
        id: 7,
        title: "Currency converter",
        img: "assets/projects/currency/currency_2.png",
        link: "https://currency-converter-zac.netlify.app",
        vid: "https://youtu.be/UEmpMSWRHaA",
        finished: true,
        filter: "Web",
        featured: false,
        git: "https://github.com/zaka59/currency-converter",
        description:"A currency converter made with react, it’s a simple currency converter in real time that coverts euros to 4 other devises. This project was made to learn the basics of react, it was my first react app. ",
        doneWith: ["nodejs","react","javascript","css","html"],
        images: ["assets/projects/currency/currency_1.png","assets/projects/currency/currency_2.png"],
    },
    {
        id: 8,
        title: "Lille's Metropolis map",
        img: "assets/projects/php_project.png",
        link: "https://lille-map.herokuapp.com/",
        vid: "",
        finished: true,
        filter: "Web",
        featured: false,
        git: "https://github.com/zaka59/lille-metropole",
        description:"An app that shows the Lille’s map with all the municipalities and their zip code, in this app you can also login and create an account, this app was a school project in 2020, The app is not deployed yet because I changed the database, the app will be available again when I reconnect the app to the new postgres database. ",
        doneWith: ["php","postgreSQL","javascript", "html","css"],
        images: ["assets/projects/lille/lille_1.png","assets/projects/lille/lille_2.png"],
    },
    {
        id: 9,
        title: "Titanic",
        img: "assets/icons/working.png",
        link: " ",
        vid: "",
        finished: false,
        filter: "Software",
        git: "",
        description: "This is the legendary Titanic Machine Learning project, the best, first challenge to dive into Machine Learning, the concept is simple: use machine learning to create a model that predicts which passengers survived the Titanic shipwreck. Also, you can enter your name, age and sex to see your probability of surviving the Titanic incident.This project will be posted soon. ",
        doneWith: ["python","jupyter"],
        images: ["assets/icons/working.png"],
    },
    {
        id: 10,
        title: "Text Editor",
        img: "assets/projects/editor/editor_2.png",
        link: " ",
        vid: "https://youtu.be/RmeifAc0KkI",
        finished: true,
        filter: "Software",
        git: "https://github.com/zaka59/html-editor",
        description: "A simple text editor where you can edit any kind of file, the particularity comes when you try to edit an html file, the html code won’t be shown, instead, the result of the html code will be shown, in other words, the editor shows the browser's result. The result can be modified which it will modify the code too. This editor reverse engineers the reversed engineer, a cool and fast way create a simple text-only page.",
        doneWith: ["python"],
        images: ["assets/projects/editor/editor_1.png","assets/projects/editor/editor_2.png"],
    },
    {
        id: 11,
        title: "Annoying Alarm Clock",
        img: "assets/icons/working.png",
        link: "",
        vid: "",
        finished: false,
        filter: "Hardware",
        git: "",
        description:
            "Random text until the project is deployed : Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        doneWith: ["arduino"],
        images: ["assets/icons/working.png"],
    },
    {
        id: 12,
        title: "Automated Plant Watering System",
        img: "assets/icons/working.png",
        link: "",
        vid: "",
        finished: false,
        filter: "Hardware",
        git: "",
        description:
            "Random text until the project is deployed : Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        doneWith: ["arduino"],
        images: ["assets/icons/working.png"],
    },
];
