import "./about.scss";
// import { octos } from "../../data/octos";
const About = ({ theme }) => {
    // const octo_divs = octos.map((octo) => (
    //     <div className="octo" id={octo.title}>
    //         <img src={octo.img} alt="img" />
    //         <h4>{octo.title}</h4>
    //         <p>{octo.description}</p>
    //     </div>
    // ));
    //<div className="octogones"></div>
    return (
        <div className={"about " + theme} id="about">
            <div className="container">
                <h3>Me, Myself & I.</h3>
                <p>
                    I’m Zack, a web/software developer and a student. I grew
                    up in Barcelona, currently I’m studying in France,
                    precisely at the University of Lille (third year). I always loved the web
                    and the new technologies, all started when I learned java at
                    12 years old, since then, I can’t stop myself from learning
                    new programming languages.
                </p>
                <p>
                    Out of the office you’ll find me dreaming of formula one,
                    football and jogging around the amazing city of Lille.
                </p>
            </div>
            <div className="zac">
                <img src={"assets/zac/zac_pc_"+theme+".png"} alt="img" />
            </div>
        </div>
    );
};

export default About;
