import Topbar from "./components/topbar/Topbar";
import Intro from "./components/intro/Intro";
import About from "./components/about/About";
import Portfolio from "./components/portfolio/Portfolio";
import Skills from "./components/skills/Skills";
import Contact from "./components/contact/Contact";
import "./app.scss";
import { useState } from "react";
import Menu from "./components/menu/Menu";
const App = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [theme, setTheme] = useState("light");

    const handleTheme = () => {
        if (theme === "light") setTheme("dark");
        else setTheme("light");
    };

    return (
        <div className="app">
            <Topbar
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                lightDarkMode={handleTheme}
                theme={theme}
            />
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} theme={theme} />
            <div className="sections">
                <Intro theme={theme} />
                <About theme={theme} />
                <Skills theme={theme} />
                <Portfolio theme={theme} />
                <Contact theme={theme} />
            </div>
        </div>
    );
};

export default App;
