import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import { projects } from "../../data/projects";
import { keyframes } from "styled-components";
const Portfolio = ({theme}) => {
    const [hoverImg, setHoverImg] = useState("");
    const [image, setImage] = useState(0);
    const emptyProject = {
        id: 0,
        title: "",
        img: "",
        link: "",
        vid: "",
        finished: "",
        filter: "",
        featured: "",
        description: "",
        git: "",
        doneWith: [],
        images: ["assets/icons/working.png"],
    };
    const [data, setData] = useState({
        d: [],
        project: emptyProject,
        visibility: false,
        selected: "featured",
    });
    const popupCloseHandler = () => {
        setImage(0);
        setData({
            d: data.d,
            project: emptyProject,
            visibility: false,
            selected: data.selected,
        });
    };
    const onHoverImg = () => {
            setHoverImg(data.project.images[image]);
    };
    const offHoverImg = () => {
        setHoverImg("");
    };
    const setSelected = (id) => {
        console.log(id);
        setData({
            d: data.d,
            project: data.project,
            visibility: data.visibility,
            selected: id,
        });
    };

    const list = [
        {
            id: "featured",
            title: "Featured",
        },
        {
            id: "Web",
            title: "Web",
        },
        {
            id: "Software",
            title: "Software",
        },
        {
            id: "Hardware",
            title: "Hardware",
        },
    ];

    const pushProject = (x) => {
        setData({
            d: data.d,
            project: x,
            visibility: true,
            selected: data.selected,
        });
    };
    useEffect(() => {
        switch (data.selected) {
            case "featured":
                setData({
                    d: projects.filter((p) => p.featured === true),
                    project: data.project,
                    visibility: false,
                    selected: data.selected,
                });
                break;
            case "Web":
                setData({
                    d: projects.filter((p) => p.filter === "Web"),
                    project: data.project,
                    visibility: false,
                    selected: data.selected,
                });
                break;
            case "Software":
                setData({
                    d: projects.filter((p) => p.filter === "Software"),
                    project: data.project,
                    visibility: false,
                    selected: data.selected,
                });
                break;
            case "Hardware":
                setData({
                    d: projects.filter((p) => p.filter === "Hardware"),
                    project: data.project,
                    visibility: false,
                    selected: data.selected,
                });
                break;
            default:
                setData({
                    d: projects.filter((p) => p.featured === true),
                    project: data.project,
                    visibility: false,
                    selected: data.selected,
                });
        }
    }, [data.selected]);



    const imagesdivs = data.visibility ? (
        <img
            src={data.project.images[image]}
            alt="img"
            onClick={() => {
                onHoverImg();
            }}
        />
    ) : (
        <> </>
    );
    const leftImage = () => {
        setImage(Math.abs((image - 1) % data.project.images.length));
    };
    const rightImage = () => {
        setImage((image + 1) % data.project.images.length);
    };

    // const by = keyframes`
    //     from { opacity: 1; }
    //     to { opacity:0.3; margin-left: -100%; }
    // `;

    const project_hide={
        display: "none",
    }
    const project_show={
        display: "block",
    }
    return (
        <div className={"portfolio "+theme} id="portfolio">
            <div
                className={
                    hoverImg !== "" ? "previewImage" : "hiddenPreviewImage"
                }
                onClick={() => {
                    offHoverImg();
                }}
            >
                <img
                    id="preview"
                    src={hoverImg}
                    alt="img"
                />
            </div>
            <h1>Portfolio</h1>
            <ul>
                {list.map((item) => (
                    <PortfolioList
                        title={item.title}
                        active={data.selected === item.id}
                        setSelected={setSelected}
                        id={item.id}
                    />
                ))}
            </ul>
            <div className="container">
                {data.d.map((x) => (
                    <div
                        className="item"
                        onClick={() => {
                            pushProject(x);
                        }}
                    >
                        <img src={x.img} alt="" />
                        <h3>{x.title}</h3>
                    </div>
                ))}
            </div>
            <div className="project" style={data.visibility ? project_show : project_hide}>
                <div className="left">
                    <div className="close" onClick={() => popupCloseHandler()}>
                        <p>X</p>
                    </div>
                    <div className="containerSlide">{imagesdivs}</div>
                    {data.project.finished ? (
                        <div className="buttons">
                            <img
                                src="assets/arrow.png"
                                className="arrow left"
                                alt=""
                                onClick={() => leftImage()}
                            />
                            <img
                                src="assets/arrow.png"
                                className="arrow right"
                                alt=""
                                onClick={() => rightImage()}
                            />
                        </div>
                    ) : (
                        <> </>
                    )}
                    <div className="social">
                        <a
                            id="git"
                            href={data.project.git}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="assets/icons/social/github.png"
                                alt="git repository"
                            />
                        </a>
                        <a
                            id="web"
                            href={data.project.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="assets/icons/social/internet.png"
                                alt="demo"
                            />
                        </a>
                        <a
                            id="youtube"
                            href={data.project.vid}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src="assets/icons/social/youtube.png"
                                alt="video demo"
                            />
                        </a>
                    </div>
                </div>
                <div className="right">
                    <div className="close" onClick={() => popupCloseHandler()}>
                        <p>X</p>
                    </div>
                    <div className="text">
                        <h4> {data.project.title}</h4>
                        <p> {data.project.description}</p>
                    </div>
                    <div className="doneWith">
                        {data.project.doneWith.map((x) => (
                            <img src={"assets/icons/skills/"+x+".png"} alt={x}/>
                        ))}
                    </div>
                    <div className="social">
                        <a
                            id="git"
                            href={data.project.git}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="assets/icons/social/github.png"
                                alt="git repository"
                            />
                        </a>
                        <a
                            id="youtube"
                            href={data.project.vid}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="assets/icons/social/youtube.png"
                                alt="video demo"
                            />
                        </a>
                        <a
                            id="web"
                            href={data.project.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="assets/icons/social/internet.png"
                                alt="demo"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Portfolio;
