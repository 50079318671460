export const data = [
        {
            id: "1",
            icon: "./assets/globe.png",
            title: "Web Devlopment",
            desc:
                "I'm a fullstack developer, I usally code my apps with Node.js and React but I also master the PHP language.",
            img: "",
            tools: [
                {
                    name: "Node.js",
                    logo: "assets/icons/skills/nodejs.png",
                    className: "small",
                    link: "https://nodejs.org/en/",
                },
                {
                    name: "React",
                    logo: "assets/icons/skills/react.png",
                    className: "normal",
                    link: "https://reactjs.org/",
                },
                {
                    name: "Mongodb",
                    logo: "assets/icons/skills/mongodb.png",
                    className: "normal",
                    link: "https://www.mongodb.com/",
                },
                {
                    name: "Express",
                    logo: "assets/icons/skills/express.png",
                    className: "small",
                    link: "https://expressjs.com/",
                },
                {
                    name: "Php",
                    logo: "assets/icons/skills/php.png",
                    className: "normal",
                    link: "https://www.php.net/",
                },
                {
                    name: "MySQL",
                    logo: "assets/icons/skills/mysql.png",
                    className: "small",
                    link: "https://www.mysql.com/",
                },
                {
                    name: "FileZila",
                    logo: "assets/icons/skills/filezila.png",
                    className: "normal",
                    link: "https://filezilla-project.org/",
                },
                {
                    name: "Postman",
                    logo: "assets/icons/skills/postman.png",
                    className: "normal",
                    link: "https://www.postman.com/",
                },
                {
                    name: "Aws",
                    logo: "assets/icons/skills/aws.png",
                    className: "normal",
                    link: "http://aws.amazon.com/",
                },
                {
                    name: "Vim",
                    logo: "assets/icons/skills/vim.png",
                    className: "normal",
                    link: "https://www.vim.org/",
                },
            ],
        },
        {
            id: "2",
            icon: "./assets/software.png",
            title: "Software Development",
            desc:
                "I'm a unix user, I create my own tools with C/C++, Java and Python.",
            img: "",
            tools: [
                {
                    name: "C",
                    logo: "assets/icons/skills/c.png",
                    className: "normal",
                    link: "https://en.wikipedia.org/wiki/C_(programming_language)",
                },
                {
                    name: "C++",
                    logo: "assets/icons/skills/cplusplus.png",
                    className: "normal",
                    link: "https://www.cplusplus.com/",
                },
                {
                    name: "Java",
                    logo: "assets/icons/skills/java.png",
                    className: "normal",
                    link: "https://www.java.com/fr/",
                },
                {
                    name: "Python",
                    logo: "assets/icons/skills/python.png",
                    className: "normal",
                    link: "https://www.python.org/",
                },
                {
                    name: "GitHub",
                    logo: "assets/icons/skills/github.png",
                    className: "normal",
                    link: "https://github.com/",
                },
                {
                    name: "Eclipse",
                    logo: "assets/icons/skills/eclipse.png",
                    className: "normal",
                    link: "https://www.eclipse.org/",
                },
                {
                    name: "Wireshark",
                    logo: "assets/icons/skills/wireshark.png",
                    className: "normal",
                    link: "https://www.wireshark.org/",
                },
                {
                    name: "VirtualBox",
                    logo: "assets/icons/skills/virtualbox.png",
                    className: "normal",
                    link: "https://www.virtualbox.org/",
                },
                {
                    name: "Arduino",
                    logo: "assets/icons/skills/arduino.png",
                    className: "normal",
                    link: "https://www.arduino.cc/",
                },
                {
                    name: "Vim",
                    logo: "assets/icons/skills/vim.png",
                    className: "normal",
                    link: "https://www.vim.org/",
                },
            ],
        },
        {
            id: "3",
            icon: "./assets/writing.png",
            title: "Office",
            desc:
                "Even us developers have to use the right tools to be organized which will increase our productivity, we have to model our projects before coding them, write down ideas, and present our projects properly.",
            img: "",
            tools: [
                {
                    name: "Word",
                    logo: "assets/icons/skills/word.png",
                    className: "normal",
                    link: "https://www.office.com/",
                },
                {
                    name: "PowerPoint",
                    logo: "assets/icons/skills/powerpoint.png",
                    className: "normal",
                    link: "https://www.office.com/",
                },
                {
                    name: "Excel",
                    logo: "assets/icons/skills/excel.png",
                    className: "normal",
                    link: "https://www.office.com/",
                },
                {
                    name: "Photoshop",
                    logo: "assets/icons/skills/photoshop.png",
                    className: "normal",
                    link: "https://www.adobe.com/fr/products/photoshop.html",
                },
                {
                    name: "StarUml",
                    logo: "assets/icons/skills/staruml.png",
                    className: "small",
                    link: "https://staruml.io/",
                },
                {
                    name: "Jupyter",
                    logo: "assets/icons/skills/jupyter.png",
                    className: "small",
                    link: "https://jupyter.org/",
                },
            ],
        },
    ];
