import { useState } from "react";
import "./skills.scss";
import {data} from "../../data/skills"
const Skills = ({theme}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleClick = (way) => {
        way === "left"
            ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 2)
            : setCurrentSlide(
                  currentSlide < data.length - 1 ? currentSlide + 1 : 0
              );
    };

    return (
        <div className={"skills "+theme} id="skills">
        <h2 className="title">Skills.</h2>
            <div
                className="slider"
                style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
            >
                {data.map((d) => (
                    <div className="container">
                        <div className="item">
                            <div className="left">
                                <div className="leftContainer">
                                    <div className="imgContainer">
                                        <img src={d.icon} alt="" />
                                    </div>
                                    <h2>{d.title}</h2>
                                    <p>{d.desc}</p>
                                    <a href="#portfolio"><span>Projects</span></a>
                                </div>
                            </div>
                            <div className="right">
                                <div className="toolsContainer">
                                    <h3>
                                        Languages, tools and services that I use
                                        :{" "}
                                    </h3>
                                    <ul>
                                        {d.tools.map((e) => (
                                            <div className="tool">
                                                <li>
                                                    {" "}
                                                    <p> {e.name} </p>
                                                    <a href={e.link}>
                                                    <img
                                                        className={e.className}
                                                        alt={e.name}
                                                        src={e.logo}
                                                    /></a>{" "}
                                                </li>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <img
                src="assets/arrow.png"
                className="arrow left"
                alt=""
                onClick={() => handleClick("left")}
            />
            <img
                src="assets/arrow.png"
                className="arrow right"
                alt=""
                onClick={() => handleClick()}
            />
        </div>
    );
};
export default Skills;
