import "./menu.scss";
const Menu = ({ menuOpen, setMenuOpen,theme }) => {
  return (

   <div className={"menu "+(menuOpen && "active "+theme)+ " "+theme}>
      <ul>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#about">About</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#skills">Skills</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </div>
  );
}
export default Menu;
