import "./topbar.scss";
import { Person, Mail } from "@material-ui/icons";

const Topbar = ({ menuOpen, setMenuOpen, lightDarkMode, theme }) => {
    return (
        <div className={"topbar " + (menuOpen && "active "+ theme) + " " + theme}>
            <div className="wrapper">
                <div className="left">
                    <a href="#intro" className="logo">
                        elkhayari.
                    </a>
                    <div className="itemContainer">
                        <Person className="icon" />
                        <span>+33 7 58 44 18 59</span>
                    </div>
                    <div className="itemContainer">
                        <Mail className="icon" />
                        <span>elkhayari1999@gmail.com</span>
                    </div>
                </div>
                <div className="right">
                    <label className="switch">
                        <input
                            type="checkbox"
                            onChange={() => lightDarkMode()}
                        />
                        <span className="slider"></span>
                    </label>
                    <div
                        className="hamburger"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <span className="line1"></span>
                        <span className="line2"></span>
                        <span className="line3"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Topbar;
