import { useState } from "react";
import emailjs from "emailjs-com";
import "./contact.scss";


const Contact = ({theme}) => {
window.addEventListener("scroll", () => { window.scrollBy(0, -50) });
    const [message, setMessage] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_ratsv4f",
                "template_aqfyte8",
                e.target,
                "user_vGZK0KqwBfiggdxg7BHyu"
            )
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        e.target.reset();
        setMessage(true);
    };
    return (
        <div className={"contact "+theme} id="contact">
            <div className="form">
                <h2>Contact.</h2>
                <form onSubmit={handleSubmit}>
                    <input type="email" name="email" placeholder="Email" required="required"/>
                    <input id="name" type="text" name="name" placeholder="Name" required="required"/>
                    <input id="subject" type="text" name="subject" placeholder="Subject" required="required"/>
                    <textarea name="message" placeholder="Message"></textarea>
                    <button type="submit">Send</button>
                    {message && <span>Thanks, I'll reply ASAP :)</span>}
                </form>
                <div className="socialmedia">
                    <a
                        href="https://www.linkedin.com/in/el-khayari/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            alt="linkedin"
                            src="assets/icons/social/linkedin.png"
                        />
                    </a>
                    <a
                        href="https://github.com/zaka59"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            alt="github"
                            src="assets/icons/social/github.png"
                        />
                    </a>
                    <a
                        href="https://twitter.com/ZakaLille"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            alt="twitter"
                            src="assets/icons/social/twitter.png"
                        />
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCT1zEsHkEB1wpWImiwuyPYg"
                        target="_blank"
                        rel="noreferrer"
                    >

                        <img
                            alt="youtube"
                            src="assets/icons/social/youtube.png"
                        />
                    </a>
                </div>
            </div>
            <div className="cv">
                <img src={"assets/zac/zac_call_"+theme+".png"} alt="img" />
                <h2 id="title">Download my CV :</h2>
                <div className="links">
                    <a href="https://drive.google.com/uc?export=download&id=13xjxTET5-h7HwpRHpSzcRpFokX_l1R2J"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <p id="light"> Light CV </p>
                    </a>
                    <a href="https://drive.google.com/uc?export=download&id=1WJkBUeUOL9GRzqx4hzugYHxTJc6F5iP0"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <p id="dark"> Dark CV </p>
                    </a>
                </div>
            </div>
        </div>
    );
};
export default Contact;
