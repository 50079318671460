import "./intro.scss";
import "./arrow.css";
import { init } from "ityped";
import { useEffect, useRef } from "react";
// import ToolTipPosition from "./../popups/ToolTipPosiiton"
const Intro = ({theme}) => {
    const textRef = useRef();

    useEffect(() => {
        init(textRef.current, {
            showCursor: true,
            backDelay: 1500,
            backSpeed: 60,
            strings: ["Student", "Developer", "Future Engineer"],
        });
    }, []);
    const class_Name = "intro "+theme;
    return (
        <div className={class_Name} id="intro">
            <div className="introContainer">
                <div className="wrapper">
                    <h2>Hello, I'm</h2>
                    <h1>Zakaria EL KHAYARI</h1>
                    <h3>
                        a <span ref={textRef}></span>
                    </h3>
                </div>
                <p id="beta">This is a beta version, the website will be updated soon.</p>
            </div>
          <a href="#about" className="ca3-scroll-down-link ca3-scroll-down-arrow" data-ca3_iconfont="ETmodules" data-ca3_icon=""></a>
        </div>
    );
};

export default Intro;
